import React from 'react';
import { Carousel, IconButton } from "@material-tailwind/react";
import { useSelector } from 'react-redux';

function ImageSlider() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <Carousel
            className="z-0 w-full h-96 rounded-lg"
            navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-5 left-2/4 z-20 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                        <span
                            key={i}
                            className={`h-2 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 centexOranceBg" : "w-4  cextexBackgroundColour"}`}
                            onClick={() => setActiveIndex(i)}
                        />
                    ))}
                </div>
            )}
            prevArrow={({ handlePrev }) => (
                <IconButton
                    variant="text"
                    color={`orange`}
                    size="lg"
                    onClick={handlePrev}
                    className="!absolute top-2/4 left-4 -translate-y-2/4"
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke={`${currentTheme === "light" ? "white" : "white"}`}
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                        />
                    </svg>
                </IconButton>
            )}
            nextArrow={({ handleNext }) => (
                <IconButton
                    variant="text"
                    color={`orange`}
                    size="lg"
                    onClick={handleNext}
                    className="!absolute top-2/4 !right-4 -translate-y-2/4"
                >
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke={`${currentTheme === "light" ? "white" : "white"}`}
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                    </svg>
                </IconButton>
            )}
        >
            <img
                alt={'Image'}
                src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/06/centex-edmonton-tr.jpg"}
                className={`absolute inset-0 w-full h-full object-cover brightness-90 `}
            />
            <img
                alt={'Image'}
                src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/06/Van-Houtte-Image-for-Go-Market.jpg"}
                className={`absolute inset-0 w-full h-full object-cover brightness-90`}
            />
            <img
                alt={'Image'}
                src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/06/go-market-Center-st.jpg"}
                className={`absolute inset-0 w-full h-full object-cover brightness-90`}
            />
            <img
                alt={'Image'}
                src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/06/centex-Midnapore-Van-Houtte.jpg"}
                className={`absolute inset-0 w-full h-full object-cover brightness-90`}
            />
            <img
                alt={'Image'}
                src={"https://centexbiz.wpcomstaging.com/wp-content/uploads/2024/06/centex-go-market-atm19st-1.jpg"}
                className={`absolute inset-0 w-full h-full object-cover brightness-90`}
            />
        </Carousel>
    )
}

export default ImageSlider