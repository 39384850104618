import React from 'react'
import { useSelector } from 'react-redux';
import AppStoreButtons from "../../media/CashbackApp/AppStoreButtons.svg"
import AppHomePage from "../../media/CashbackApp/AppHomeScreen.svg"
import AnimateOnScroll from '../../utilities/AnimateOnScroll';

function CentexCashbackApp() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section
            id="centexCashbackAppSection"
            className={`py-24  
                ${currentTheme === "light"
                    ? "bg-gradient-to-br from-[#ffffff00] to-[#B9D6E9]"
                    : "bg-gradient-to-br from-[#07284100] to-[#072841]"
                }`}
        >
            <div className='section grid md:grid-cols-3 grid-cols-1'>
                <AnimateOnScroll className={"col-span-2 my-auto"}>
                    {/* <div className='col-span-2'> */}
                    {/* Title */}
                    <div className='text-center md:text-left'>
                        <h2
                            className={`headerText mb-5
                                    ${currentTheme === "light"
                                    ? "centexTextColour"
                                    : "centexOrangeText"
                                }`}
                        >
                            Say hello to the new Centex Cashback App!
                        </h2>

                        {/* Description */}
                        <p className={`mb-8 bodyText mr-28
                                ${currentTheme === "light" ? "centexTextColour" : ""}
                                `}>
                            We're excited to introduce more savings, great new features, and our
                            <strong className='font-bold'> NEW Centex Cashback Sweepstakes!</strong> Download it now via
                            the links below to get started.
                        </p>

                        {/* App Store Buttons */}
                        <a href='https://qrplanet.com/k23bn7' target="_blank"><img src={AppStoreButtons} alt='App Store button'
                            className="w-[369px] mb-6" />
                        </a>

                    </div>
                    {/* More than just savings */}
                    <div
                        className={`rounded-3xl shadow-lg p-6 lg:pt-14 lg:pb-10 md:-mr-64
                            ${currentTheme === "light"
                                ? "bg-gradient-to-l from-[#ffffff00] to-[#B9D6E9]"
                                : "bg-gradient-to-l from-[#07284100] to-[#072841]"
                            }`}
                    >
                        <h2
                            className={`headerText text-center md:text-left
                                 font-extrabold mb-6 pr-5 
                                ${currentTheme === "light"
                                    ? "centexTextColour"
                                    : "centexOrangeText"
                                }`}
                        >
                            More than just savings
                        </h2>
                        <ul className={`bodyText list-disc ml-6 sm:pr-16 md:pr-56
                            ${currentTheme === "light" && "centexTextColour"}`}
                        >
                            <li className="">Earn cashback on every fuel up, in-store purchase*, carwash & more.</li>
                            <li className="">See your sweepstakes entries, and monthly winners.</li>
                            <li className="">Easily check your cashback balance, and transfer funds</li>
                            <li className="">Find the nearest Centex Cashback locations.</li>
                        </ul>
                    </div>
                    {/* </div> */}
                </AnimateOnScroll>

                {/* Phone App Image */}
                <div className="hidden md:flex justify-end">
                    <img
                        src={AppHomePage}
                        className="h-[686px] relative right-0"
                        alt="Centex Cashback App preview"
                    />
                </div>
            </div>
        </section>
    )
}

export default CentexCashbackApp