import React from 'react'
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function Opportunities() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    
    return (
        <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <div>
                <h1 className='font-semibold text-xl text-center p-5'>Be BOLD, Think Fresh, Be Centex!</h1>
                <p className=''>Since 1986, our journey of growth and success has been driven by our dedicated dealers and employees.</p>
                <p>Join us today and become a part of our thriving team!</p>
            </div>
            <div className='text-left py-4'>
                <h3>Franchise / Dealer</h3>
                <p>Do you own a gas station under another brand whose branding agreement is expiring? Or are you planning to start a new gas station and looking for the right branding partner? Look no further!</p>
                <h3>Why Choose Centex?</h3>
                <ul>
                    <ul>Experience and Expertise: We bring decades of experience in project development, systems, and marketing to help your business thrive.</ul>
                    <ul>Focused Support: With our smaller, more manageable network, we provide personalized attention to each site, far exceeding the support typically offered by major retailers.</ul>
                    <ul>Tools for Success: We equip you with all the necessary tools and resources to ensure your business reaches its full potential.</ul>
                </ul>
            </div>

            <div className='text-left py-4'>
                <h1 className=''>Explore Career Opportunities</h1>
                <p>We offer a variety of employment positions at both our corporate headquarters and dealer locations. Whether you're looking for a part-time role or a full-time career, Centex has a place for you.</p>
                <h3>Current Openings:</h3>
                <ul>
                    <li>Cashier - Part time</li>
                </ul>
            </div>
            <div className='text-left py-4'>
                <h3>Let's Get Started!</h3>
                <p>If you're ready to explore branding options with Centex or want to learn more about our employment opportunities, don't hesitate to reach out. Together, we can achieve great things!</p>
            </div>
            <div className='text-left py-4'>
                <p></p>
            </div>
        </section>
    )
}

export default Opportunities