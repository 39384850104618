import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';

function History() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    return (
        <section className={`section min-h-screen pt-32
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <div className='mb-8'>
                <h2 className='text-3xl font-bold mb-4'>Over 30 Years of Excellence</h2>
                <p className='mb-4'>Since our humble beginnings in 1986 with our first location in Inglewood, Calgary, Alberta, Centex has grown significantly. Over the past three decades, we've expanded our reach while steadfastly maintaining our core values, which are the cornerstone of our success:</p>
                <ul className='list-disc pl-5'>
                    <li>Respecting Others: We treat our customers, employees, and partners with utmost respect, fostering a welcoming and inclusive environment.</li>
                    <li>Being Accountable: We take responsibility for our actions, ensuring transparency and trust in all our dealings.</li>
                    <li>Working as a Team: Collaboration and teamwork are integral to our operations, driving innovation and efficiency.</li>
                    <li>Having Fun at What We Do: We believe that a positive and enjoyable workplace is crucial to our success, and we strive to make Centex a great place to work and do business.</li>
                </ul>
                <p>These values are not just words—they are the foundation of our success and the driving force behind our continued growth. We are proud of our history and excited for the future, as we continue to bring exceptional service and competitive pricing to our customers.</p>
            </div>
            <div className='mb-8'>
                <h2 className='text-3xl font-bold mb-4'>Join the Centex community today and experience the difference!</h2>
                <p className='mb-4'>As we continue to grow and innovate, we invite you to become a part of Centex. Whether you're a customer, employee, or franchisee, you will experience our unwavering commitment to excellence that has defined us for over three decades.</p>
                <p className='mb-4'>Together, we can achieve even greater heights while staying true to the values that make Centex a unique and respected name in the industry.</p>
            </div>
        </section>
    );
}

export default History;
