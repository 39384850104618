import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GoMarketSerice from "./GoMarketService";

const GoMarketSlice = createSlice({
    name: "goMarket",
    initialState: {
        allContent: [],
        singleContent: null,
        isError: false,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGoMarketContent.pending, (state) => {
                // Update loading and error states when fetching promos is pending
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getGoMarketContent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false; // Reset error state
                state.allContent = action.payload;
            })
    }
})

export const getGoMarketContent = createAsyncThunk(
    "GoMarket/getAll",
    async (_, thunkAPI) => {
        try {
            // Fetch from  service
            const response = await GoMarketSerice.getAllGoMarketContent();
            return response; // Access response data
        } catch (error) {
            // Handle errors during the async operation
            const message = error.response?.data?.message || error.message || error.toString();
            console.error("Error fetching content:", message); // Log error message
            throw error; // Throw the error for Redux Toolkit to handle
        }
    }
)

export default GoMarketSlice.reducer;
export const selectAllGoMarketContent = (state) => state.goMarket.allContent;

