import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';
import FirstNationsBannerImg from "../media/FirstNations/FirstNationsBannerImg.png";
import BearspawLocationImg from "../media/FirstNations/Bearspaw_7 1.png"
import AnimateOnScroll from '../utilities/AnimateOnScroll';

function FirstNations() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`min-h-screen
      ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      {/* Hero Section */}
      <div className="h-screen flex flex-col overflow-hidden">
        <img
          alt="Semi truck driving on highway through mountains"
          src={FirstNationsBannerImg}
          className="object-cover h-screen w-screen absolute "
        />
        <div className={`absolute inset-0 h-screen bg-[#000000] ${currentTheme === "light" ? "opacity-10" : "opacity-50"}`}></div>

        <div className="section z-10 text-center md:text-right pt-32 md:pt-60 w-full">
          <h1 className={`text-2xl md:text-6xl font-extrabold ${currentTheme === "light" ? "text-white" : "centexOrangeText"}`}>
            We are proud of our
            <br />
            first nation dealer sites
            <br />
            throughout Canada
          </h1>
        </div>
      </div>
      {/* Quote Section */}
      <div className={`section py-12 text-center `}>
        <h2 className={`headerText font-bold pb-10 ${currentTheme === "light" ? "text-[#4B595B]" : "centexOrangeText"}`}>Our dealers gain many advantages by choosing Centex</h2>
        <p className='pb-5 bodyText'>
          "Our main goal and motive are not to have more gas stations on First Nation sites. We are not driven by that.
          We want to play a role in facilitating ownership by the community to benefit them. We are there to help them
          through the entire process from building the site to its operation."
          <br />
          <br />
          - Alnoor Bhura, CEO Centex
        </p>
      </div>
      {/* Canadian based Alternative section */}
      <div className={`py-20 ${currentTheme === "light"
        ? "bg-gradient-to-r from-[#9F9F9F1A] to-[#75ADD40D]"
        : "bg-gradient-to-r from-[#07284100] to-[#072841]"}`
      }>
        <div className='section flex gap-10'>
          <img src={BearspawLocationImg} className="w-full md:w-[571px]" alt="GoMarket Store" />

          {/* Text Content */}
          <AnimateOnScroll className="lg:w-1/2 text-center lg:text-left flex flex-col my-auto">
            <h2
              className={`headerText mb-8 font-extrabold pr-10
                  ${currentTheme === "light"
                  ? "centexOrangeText"
                  : "centexOrangeText"
                }`}
            >
              We are a Canadian-based alternative fuel supply
            </h2>
            <p className="bodyText">
              Centex is a proudly Canadian fuel supplier with over 150 locations nationwide, including 22 partnerships with
              First Nation communities and more on the way, offering a trusted and supportive partnership opportunity tailored
              for Indigenous businesses.
            </p>
          </AnimateOnScroll>
        </div>
      </div>
      {/* Fuel supploers section */}
      <div className={`section py-12 text-center border-black border-b-2 ${currentTheme === "light" ? "text-[#4B595B]" : "centexOrangeText"}`}>
        <h2 className={`headerText font-extrabold pb-8 `}>Guaranteed Fuel Supply Across Canada</h2>
        <p className='pb-5 bodyText'>
          Centex has fuel supply agreements with multiple major refiners, ensuring fuel supply reliability
        </p>
      </div>
    </section>
  );
}

export default FirstNations;

