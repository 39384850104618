import React from 'react'
import { useSelector } from 'react-redux';
import GoMarketStoreImg from '../../media/GoMarketQuickServe/GoMarketStoreImg.png'
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';
import QuickServeRestaurentsImg from '../../media/GoMarketQuickServe/QuickServeRestaurents.svg'
import AnimateOnScroll from '../../utilities/AnimateOnScroll';


function GoMarketQuickServe({ showbutton }) {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section id="goMarketSection" className="flex flex-col justify-center items-center bg-opacity-50">
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 py-12 lg:py-20 items-center lg:items-start section">
                {/* GoMarket Store Image */}
                <img
                    src={GoMarketStoreImg}
                    className="w-full max-w-xs"
                    alt="GoMarket Store"
                />
                {/* Text Content */}
                <AnimateOnScroll className="w-full text-center lg:text-left mt-8 lg:my-auto px-4 sm:px-8 lg:px-0">
                    <h2
                        className={`headerText font-bold
                            ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}
                    >
                        GoMarket & Quick Service Restaurants
                    </h2>
                    <h3
                        className={`bodyText
                            ${currentTheme === "light" ? "centexOrangeText" : ""}`}
                    >
                        All the conveniences you're looking for
                    </h3>
                    <p
                        className={`text-[16px] py-4 lg:py-8
                            ${currentTheme === "light" ? "centexTextColour" : ""}`}
                    >
                        Grab what you need and go with Centex's Go Market. Our GoMarket convenience stores are stocked full of everyday necessities, including snack foods, soft drinks, hot and fresh foods, coffee, tea, specialty beverages, toiletries, magazines, and more.
                    </p>
                    {showbutton &&
                        <HeaderLinkButton
                            link="/goMarket/"
                            title="Learn More"
                            className=" bg-[#F99D24]"
                        />
                    }
                </AnimateOnScroll>
            </div>

            {/* Quick Service Restaurants Image */}
            <div className="bg-[#4B595B] w-full py-8 lg:py-12 px-3">
                <img src={QuickServeRestaurentsImg} className="mx-auto max-w-full lg:max-w-3xl" alt="Quick Service Restaurants" />
            </div>
        </section>

    )
}

export default GoMarketQuickServe