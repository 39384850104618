import React from 'react'
import { NavLink } from 'react-router-dom'

function HeaderLink({ title, link }) {
    return (
        <NavLink
            to={link}
            key={link}
            className={
                ({ isActive }) =>
                isActive ? "underline underline-offset-8 scale-110 text-[#F99D24] text-center" : "cursor-pointer hover:scale-110 text-center hover:text-[#F99D24] transition-all"
            }
        >
            <h1>{title}</h1>
        </NavLink>
    )
}

export default HeaderLink