import React from 'react'
import FuelProgramImg from "../../media/FuelProgram/carsAndFuleCard.png"
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';
import AnimateOnScroll from '../../utilities/AnimateOnScroll';

function FuelProgremSection() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section
            id="centexFuelProgramSection"
            className={`relative flex py-10
                ${currentTheme === "light"
                    ? "bg-[#DDDDDD]"
                    : ""
                }`}
        >
            <div className={"flex flex-col lg:flex-row items-center justify-center w-full section"}>
                {/* <div className="flex flex-col lg:flex-row items-center justify-center w-full section mx-auto"> */}
                <div className="flex justify-center lg:justify-start w-1/2">
                    <img
                        src={FuelProgramImg}
                        className="w-full h-auto"
                        alt="Centex Cashback App preview"
                    />
                </div>

                <AnimateOnScroll className="lg:w-1/2 flex flex-col justify-between text-center items-center lg:items-end lg:text-right lg:pr-3 lg:pt-5 relative">

                    <h2
                        className={`headerText mb-7
                                ${currentTheme === "light"
                                ? "centexTextColour"
                                : "centexOrangeText"
                            }`}
                    >
                        Unlock exclusive benefits with the Centex Fuel Pass!
                    </h2>

                    {/* Description */}
                    <p className={`mb-5 bodyText 
                        ${currentTheme === "light" && "centexTextColour"}`
                    }>
                        If you have a fleet of vehicles and are looking for a convenient solution to your fuel requirements, why not check out our Fuel Pass program.
                    </p>

                    <HeaderLinkButton
                        title={"Learn More"}
                        link={"/fuelprogram/"}
                        className={"text-[16px] font-bold py-3 px-16 bg-[#4B595B]"}
                    />
                </AnimateOnScroll>
                {/* </div> */}
            </div>
        </section>
    )
}

export default FuelProgremSection