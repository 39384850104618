import React from 'react'
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';
import communitiesBannerImg from "../media/Communities/communitiesBanner.png"
import PovertyReliefAfricaImg from "../media/Communities/PovertyReliefAfricaImg.png"
import PovertyReliefAfricaLogo from "../media/Communities/PovertyReliefAfricaLogo.png"
import MalvaWAterImg from "../media/Communities/MalvaWaterImg.png"
import MalvaWAterLogo from "../media/Communities/MalawiWaterLogo.png"
import Mom2momImg from "../media/Communities/mom2momglobalImg.png"
import Mom2MomLogo from "../media/Communities/mom2momglobalLogo.png"
import ChildHavenImg from "../media/Communities/ChildHavenImg.png"
import ChildHavenLogo from "../media/Communities/ChileHavenLogo.png"
import UmojaImg from "../media/Communities/UmojaImg.png"
import UmojaLogo from "../media/Communities/UmojaLogo.png"
import ChildIsInnocentImg from "../media/Communities/ChildIsInnocentImg.png"
import ChildIsInnocentLogo from "../media/Communities/ChildIsInnocentLogo.png"
import OziaTrustImg from "../media/Communities/OziaTrustImg.png"
import OziaTrustLogo from "../media/Communities/OziaTrustLogo.png"
import AnimateOnScroll from '../utilities/AnimateOnScroll';

function Communities() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    return (
        <section className={`min-h-screen relative
            ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            {/* Hero Section */}
            <div className='h-screen relative flex items-end'>
                <img
                    alt='Semi truck driving on highway through mountains'
                    src={communitiesBannerImg}
                    className='object-cover h-screen w-screen absolute'
                />
                <div className={`absolute inset-0 bg-[#005DA4] opacity-60 ${currentTheme === "" && "brightness-50"}`}></div>
                <div className='section w-full z-10 text-center pb-56'>
                    <h1 className={`relative lg:text-7xl text-4xl font-bold ${currentTheme === "light" ? "text-white" : "centexOrangeText"}`}>
                        We believe in giving back.
                    </h1>
                    <p className='descriptionText pt-12 text-white'>
                        At Centex, we are committed to giving back to communities both in Canada and around the world. Through partnerships that
                        support healthcare, emergency response, poverty relief, clean water access, and more, we aim to make a positive impact
                        for families, women, and children locally and globally.                    </p>
                </div>
            </div>
            {/* Learn more about.. Title */}
            <div className='section text-center py-14'>
                <h2 className={`subHeadderText font-bold`}>
                    Learn more about the organizations we are proud to support:
                </h2>
            </div>
            {/* Poverty Relief Africa --- Left Img*/}
            <div className={`drop-shadow-2xl ${currentTheme === "light"
                ? "bg-gradient-to-r from-[#ffffff00] to-[#b9d6e957] centexTextColour"
                : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                }`}>
                <div className={`flex flex-col lg:flex-row gap-10 py-20 items-center lg:items-start section`}>
                    {/* Image Container */}
                    <img
                        src={PovertyReliefAfricaImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left 
                    `}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Poverty Relief Africa
                        </h3>
                        <p className="mb-6">
                            Centex is a proud and dedicated supporter of Poverty Relief Africa, committed to making a positive impact.
                            <br />
                            <br />
                            Poverty Relief Africa is dedicated to alleviating the physical, emotional, and intellectual poverty faced by children
                            and women in war-torn northern Uganda. They focus on providing education, life skills, leadership training,
                            and support for income generation.
                            <br />
                            <br />
                            Their mission is to foster the next generation of community leaders in Gulu, northern Uganda, by delivering
                            high-quality primary, secondary, and post-secondary academic and vocational programs to 100 students who
                            have been impacted by a brutal 20-year insurgency. Second Hope assists women in extreme poverty to achieve
                            self-sufficiency by offering support through collaboration, training, and income generation activities
                        </p>
                        <div className='md:flex gap-10'>
                            <img src={PovertyReliefAfricaLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://www.canadahelps.org/en/charities/poverty-relief-africa/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    canadahelps.org/en/charities/poverty-relief-africa
                                </a>
                                <p>Business No. 834266744RR0001 CRA REGISTERED</p>
                            </div>
                        </div>
                    </AnimateOnScroll>
                </div>
            </div>
            {/* Malva Water Project */}
            <div className={`drop-shadow-2xl`}>
                <div className={`flex flex-col lg:flex-row gap-8 py-20 items-center lg:items-start section ${currentTheme === "light" && "centexTextColour"}`}>
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left`}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Malawi Water Project
                        </h3>
                        <p className="mb-6">
                            Centex proudly supports the Malawi Water Project with an annual donation, helping to bring
                            clean water and hope to communities in need.                            <br />
                            <br />
                            In Malawi, one of the world's least-developed countries, access to safe drinking water is vital.
                            The Malawi Water Project improves lives by providing clean water to children and families, reducing
                            waterborne illness, and promoting health in rural areas.
                            <br />
                            <br />
                            Local villagers receive training in sanitation and hygiene, along with support for building and
                            installing Biosand Water Filters. Originally designed at the University of Calgary, these low-cost
                            filters are durable and highly effective at removing harmful pathogens, offering a lifetime of
                            safe water for households.
                        </p>
                        <div className='md:flex gap-10'>
                            <img src={MalvaWAterLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://malawiwaterproject.com/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    malawiwaterproject.com
                                </a>
                                <p>Canadian Charity No. BN107010886RR0001</p>
                            </div>
                        </div>
                    </AnimateOnScroll>
                    {/* Image Container */}
                    <img
                        src={MalvaWAterImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                </div>
            </div>
            {/* Mom2Mom Global --- Left Img*/}
            <div className={`drop-shadow-2xl ${currentTheme === "light"
                ? "bg-gradient-to-r from-[#ffffff00] to-[#b9d6e957] centexTextColour"
                : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                }`}>
                <div className={`flex flex-col lg:flex-row gap-10 py-20 items-center lg:items-start section`}>
                    {/* Image Container */}
                    <img
                        src={Mom2momImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left 
                    `}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Mom2Mom Global
                        </h3>
                        <p className="mb-6">
                            Centex is a proud supporter of Mom2Mom Global, a vital network providing breastfeeding
                            support and advocacy for military families worldwide.
                            <br />
                            <br />
                            Global Military Lactation Community is a nonprofit, organization dedicated to lactation peer support, education, and advocacy for military families.
                            Started in 1999 at Landstuhl Regional Medical Center (LRMC) in Germany as Mom2Mom, Global Military Lactation Community (Global MilCom) provides individual
                            and group lactation support to help families meet their unique  goals. Global MilCom is also home to the Military Lactation Counselor® professional credential.
                            <br />
                            <br />
                            The organization also provides lactation advocacy, information, and support for parents serving on Active Duty, in the National Guard, and in the Reserves.
                        </p>
                        <div className='md:flex gap-10'>
                            <img src={Mom2MomLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://globalmilcom.org/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    mom2momglobal.org
                                </a>
                                <p>Business No. 834266744RR0001 CRA REGISTERED</p>
                            </div>
                        </div>
                    </AnimateOnScroll>
                </div>
            </div>
            {/* Child Haven International */}
            <div className={`drop-shadow-2xl`}>
                <div className={`flex flex-col lg:flex-row gap-8 py-20 items-center lg:items-start section ${currentTheme === "light" && "centexTextColour"}`}>
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left`}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Child Haven International
                        </h3>
                        <p className="mb-6">
                            At Centex, we strongly believe in the importance of giving back to the community. If we could make one wish, it would be to ensure
                            that children facing unfortunate circumstances receive the support they need not only to survive but to thrive. We channel our
                            efforts towards development by fostering a strong partnership with Child Haven International.
                            <br />
                            <br />
                            Each year, we contribute to causes that matter, and we are excited to announce our upcoming Child Haven Week at all Centex locations.
                            During this special week, with every purchase, customers will have the opportunity to donate a Loonie ($1.00 CAD) towards the orphanages
                            supported by Child Haven International, and Centex will match every donation. Stay tuned for more details—this initiative is launching soon!
                            <br />
                            <br />
                            Inspired by the principles of Mahatma Gandhi, Child Haven International provides essential support: food, education, healthcare, shelter,
                            clothing, and emotional and moral care to children and women in developing countries who are in great need.
                        </p>
                        <div className='md:flex gap-7'>
                            <img src={ChildHavenLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://childhaven.ca/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    childhaven.ca
                                </a>
                            </div>
                        </div>
                    </AnimateOnScroll>
                    {/* Image Container */}
                    <img
                        src={ChildHavenImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                </div>
            </div>
            {/* Umoja Community Mosaic --- Left Img*/}
            <div className={`drop-shadow-2xl ${currentTheme === "light"
                ? "bg-gradient-to-r from-[#ffffff00] to-[#b9d6e957] centexTextColour"
                : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                }`}>
                <div className={`flex flex-col lg:flex-row gap-10 py-20 items-center lg:items-start section`}>
                    {/* Image Container */}
                    <img
                        src={UmojaImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left 
                    `}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Umoja Community Mosaic
                        </h3>
                        <p className="mb-6">
                            Centex proudly supports the Umoja Community Mosaic.
                            <br />
                            <br />
                            Umoja Community Mosaic began in 2010 as a drop-in soccer program called Soccer Without Boundaries.
                            As a small grassroots initiative, Soccer Without Boundaries made a significant impact on social inclusion,
                            using the power of soccer to foster healthy communities and create an environment where young people could
                            thrive under the motto, “Everyone is welcome.”
                            <br />
                            <br />
                            In 2020, in response to the challenges posed by the COVID-19 pandemic, they expanded their services and
                            broadened their organizational mandate. To reflect this growth, they adopted the name Umoja Community Mosaic,
                            drawing from the Swahili word "Umoja," meaning "unity" and "belonging."
                            <br />
                            <br />
                            By listening to the needs of the communities they serve, they launched a culturally appropriate food and
                            nutrition program to support low-income and underserved Calgarians. Alongside this, they continue to offer
                            after-school programming, tutoring, and weekly soccer sessions. They also host summer soccer camps and are
                            excited to launch a new girls' soccer program for older youth, planned for early next year. Additionally,
                            they have formally integrated anti-racism initiatives into all of their work and are focusing on mental
                            health support within their regular soccer programming.
                        </p>
                        <div className='md:flex gap-10'>
                            <img src={UmojaLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://umojamosaic.org/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    umojamosaic.org
                                </a>
                                <p>Charitable Registration Number: 822300596RR0001</p>
                            </div>
                        </div>
                    </AnimateOnScroll>
                </div>
            </div>
            {/* The Child is Innocent */}
            <div className={`drop-shadow-2xl`}>
                <div className={`flex flex-col lg:flex-row gap-8 py-20 items-center lg:items-start section ${currentTheme === "light" && "centexTextColour"}`}>
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left`}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            The Child is Innocent
                        </h3>
                        <p className="mb-6">
                            Centex is a proud supporter of The Child is Innocent, educating today's children and tomorrow's leaders.
                            <br />
                            <br />
                            Founded in Northern Uganda in 2004, The Child is Innocent believes that the individuals best equipped to
                            rebuild and develop post-conflict communities facing poverty are those from within, empowered through high-quality
                            formal education, leadership skills, and a strong network of peer support. These tools enable them to improve the
                            lives of their friends, families, and neighbors.
                            <br />
                            <br />
                            The Child is Innocent offers comprehensive educational scholarships to children from Northern Uganda, ages 7 to 22,
                            allowing them to attend some of the top boarding schools in Uganda. This is paired with an innovative leadership curriculum,
                            led by graduates of the scholarship program, to foster both opportunity and a supportive community emerging directly from Northern Uganda.
                        </p>
                        <div className='md:flex gap-7'>
                            <img src={ChildIsInnocentLogo} className='h-28 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://thechildisinnocent.org/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    thechildisinnocent.org
                                </a>
                            </div>
                        </div>
                    </AnimateOnScroll>
                    {/* Image Container */}
                    <img
                        src={ChildIsInnocentImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                </div>
            </div>
            {/* Ozîja Thiha Education Trust --- Left Img*/}
            <div className={`drop-shadow-2xl ${currentTheme === "light"
                ? "bg-gradient-to-r from-[#ffffff00] to-[#b9d6e957] centexTextColour"
                : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                }`}>
                <div className={`flex flex-col lg:flex-row gap-10 py-20 items-center lg:items-start section`}>
                    {/* Image Container */}
                    <img
                        src={OziaTrustImg}
                        className="w-full md:w-3/6"
                        alt="GoMarket Store"
                    />
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-2/3 pt-10 md:pt-0 text-center lg:text-left 
                    `}>
                        <h3 className={`descriptionText font-bold pb-7 ${currentTheme === "dark" && "centexOrangeText"}`}>
                            Ozîja Thiha Education Trust
                        </h3>
                        <p className="mb-6">
                            Centex is honored to be a supporter of the Ozîja Thiha Education Trust, to assist Bearspaw students present & future.
                            <br />
                            <br />
                            The Ozîja Thiha Education Trust (OTET) was established by the Bearspaw Chief and Council to  to assist and recognize
                            qualifying Bearspaw Students financially through incentives, scholarships and award programs at the grade and
                            post-secondary education levels.
                            <br />
                            <br />
                            The purpose and mission of the Ozîja Thiha Trust is to assist and encourage in the educational development of the human
                            resources of the Bearspaw Nation with the view for members to successfully complete their education at the grade,  upgrading,
                            trades, college and university levels and obtain a profession, occupation or skill that will benefit themselves and in turn
                            Bearspaw First Nation as a whole.
                        </p>
                        <div className='md:flex gap-10'>
                            <img src={OziaTrustLogo} className='w-36 mx-auto md:mx-0' />
                            <div className='flex flex-col mt-auto pt-10 md:pt-0 gap-y-5 text-xs md:text-base'>
                                <a
                                    href='https://otet.ca/'
                                    target="_blank"
                                    className='font-semibold underline'
                                >
                                    otet.ca
                                </a>
                            </div>
                        </div>
                    </AnimateOnScroll>
                </div>
            </div>
        </section>
    )
}

export default Communities