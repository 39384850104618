import React, { useState } from 'react';
import { Carousel, IconButton } from "@material-tailwind/react";
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';

function HomeCarousel({ allPromos }) {
    const currentTheme = useSelector(state => state.ui.theme);
    const [activeIndex] = useState(0);
    

    const reversedAllPromos = allPromos.reverse()
    return (
        <div className={`${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            <Carousel
                className="z-0"
                autoplay={true}
                autoplayDelay={20000}
                loop={true}
                index={activeIndex}
                navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute bottom-4 z-20 flex gap-3 md:gap-10 w-screen items-center justify-center">
                        {new Array(length).fill("").map((_, i) => (
                            <button
                                key={i}
                                className={`py-1 lg:py-3 cursor-pointer transition-all border-t-4 font-semibold md:w-72 md:h-10  ${activeIndex === i ? `px-7 md:px-0 border-[#F99D24] scale-105` : "px-4 md:px-0 hover:scale-105 border-[#0C416A]"}`}
                                onClick={() => setActiveIndex(i)}
                            >
                                <p className='hidden md:block'>{reversedAllPromos[i].title.rendered}</p>
                            </button>
                        ))}
                    </div>
                )}
                prevArrow={({ handlePrev }) => (
                    <IconButton
                        variant="text"
                        color={`orange`}
                        size="lg"
                        onClick={handlePrev}
                        className="!absolute top-2/4 -left-2 -translate-y-2/4 h-screen"
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke={`${currentTheme === "light" ? "black" : "white"}`}
                            className="h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                            />
                        </svg>
                    </IconButton>
                )}
                nextArrow={({ handleNext }) => (
                    <IconButton
                        variant="text"
                        color={`orange`}
                        size="lg"
                        onClick={handleNext}
                        className="!absolute top-2/4 -right-2 -translate-y-2/4"
                    >
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke={`${currentTheme === "light" ? "black" : "white"}`}
                            className=" h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                        </svg>
                    </IconButton>
                )}
            >
                {reversedAllPromos?.map((promo, index) => (
                    <section key={index} className='w-full min-h-screen md:h-screen overflow-hidden relative flex'>
                        {promo.acf.promo_video ?
                            <>
                                <div dangerouslySetInnerHTML={{
                                    __html: `<video 
                                    loop muted autoplay playsinline
                                    src="${promo.acf.promo_video}"
                                    class="w-full h-full object-cover absolute top-0 z-0"
                                />`
                                }}>
                                </div>
                                {/* <VscUnmute className='absolute top-36 right-5 text-4xl hover:scale-105 hover:cursor-pointer'/> */}
                            </>
                            :
                            <img
                                alt={promo.acf.background_image?.title || 'Promo Image'}
                                src={promo.acf.background_image?.url}
                                loading='lazy'
                                className={`absolute inset-0 w-full h-full object-cover`}
                            />
                        }
                        {/* <div className={`absolute h-1/4 top-[80vh] inset-0 ${currentTheme === "light" ? "bg-gradient-to-t from-[#ffffff8c] to-[#fff0]" : "bg-gradient-to-t from-[#111111] to-[#fff0]"}`}></div> */}
                        {/* Promo Information */}
                        <div className={`transition-all px-8 pt-5 h-fit absolute bottom-0 pb-14 md:pb-20 w-full 
                            ${currentTheme === "light" ? "lightTransparentBg" : "darkTransparentBg"}`}>
                            <div className='section text-center'>
                                <h1 className={`headerText
                                    ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                                    {promo.title.rendered}
                                </h1>
                                <div className='mb-6 font-thin'>
                                    <p className='subHeadderText pb-3'>{promo.acf.promo_information}</p>
                                    <p className='bodyText'>{promo.acf.promo_information_2}</p>
                                </div>
                                <HeaderLinkButton link={promo.acf.promolink} title={"Learn More"} className={""} />
                            </div>
                        </div>
                    </section>
                ))}
            </Carousel>
        </div>
    )
}
export default HomeCarousel;
