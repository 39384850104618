import axios from "axios";

const BASE_URL = process.env.REACT_APP_WP_RESTAPI_ENDPOINT;

const getAllPromos = async () => {
  try {
    const response = await axios.get(BASE_URL + "/home-page-promo/");
    return response.data;
  } catch (error) {
    console.error('Error fetching Locations:', error);
    throw new Error('Failed to fetch locations');
  }
};

const HomePagePromoService = {
  getAllPromos
}
export default HomePagePromoService;