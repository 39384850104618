import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import BlueSpinner from '../components/spinnders/Spinners';
import iconImg from "../media/map_pin.svg";
import { useSelector } from 'react-redux';
import { MapsDarkModeStyles } from '../utilities/MapsDarkModeStyles';


const GoogleMaps = ({ locations, hoveredLocation, selectedLocation, userLocation }) => {
    let defaultCenter = {
        lat: 55.00,
        lng: -110.00
    };
    const defaultZoom = 4.5;
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const [map, setMap] = useState(null);
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey });
    const markersRef = useRef([]);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const currentTheme = useSelector(state => state.ui.theme);

    const onLoad = React.useCallback(mapInstance => setMap(mapInstance), []);
    const onUnmount = React.useCallback(() => setMap(null), []);

    const DarkModeStyles = MapsDarkModeStyles;

    const handleGetDirections = (location) => {
        // Define origin and destination
        const origin = userLocation
        const destination = { lat: parseFloat(location.acf.lat), lng: parseFloat(location.acf.lng) }

        // Create a DirectionsService instance
        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,

            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirectionsResponse(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    };

    // pin users location
    useEffect(() => {
        if (!isLoaded || !map) return;
        new window.google.maps.Marker({
            position: userLocation,
            map: map,
            title: 'Your Location',
        });
    }, [userLocation])

    //Mark all locations
    useEffect(() => {
        if (!isLoaded || !map) return;

        const clearMarkers = () => {
            markersRef.current.forEach(marker => marker.setMap(null));
            markersRef.current = [];
        };

        const addMarkers = () => {
            try {
                locations.forEach(location => {
                    const marker = new window.google.maps.Marker({
                        position: { lat: parseFloat(location.acf.lat), lng: parseFloat(location.acf.lng) },
                        map: map,
                        title: location.title.rendered,
                        id: location.id,
                        icon: {
                            url: iconImg,
                            scaledSize: new window.google.maps.Size(55, 25)
                        },
                    });
                    //  <img src="${location.acf.location_image}" alt="Location Photo">
                    const infoWindowContent = `
                             <div class="info-window">
                                <h2>${location.title.rendered}</h2>
                               
                                <p>${location.acf.phone_number}</p>
                                <p>${location.acf.address + ", " + location.acf.city + ", " + location.acf.provence}</p>
                             </div>
                         `;

                    const infoWindow = new window.google.maps.InfoWindow({
                        content: infoWindowContent
                    });

                    marker.addListener('click', () => {
                        infoWindow.open(map, marker);
                        map.setCenter(marker.getPosition()); // Zoom in to the clicked marker
                        // map.setZoom(17);
                        handleGetDirections(location);
                    });

                    // Listen for the closeclick event of the info window
                    infoWindow.addListener('closeclick', () => {
                        // map.setZoom(13);
                        setDirectionsResponse(null)
                    });

                    markersRef.current.push(marker);
                });
            } catch (error) {
                console.log(error);
            }
        };

        clearMarkers();
        addMarkers();

        return () => clearMarkers();
    }, [locations, map]);

    // manage selected location effect.
    useEffect(() => {
        markersRef.current.forEach(marker => {
            const infoWindowContent = `
                <div class="info-window">
                    <h2>${marker.title}</h2>
                    <img src="${locations.find(location => location.id === marker.id).acf.location_image}" alt="Location Photo">
                    <p>${locations.find(location => location.id === marker.id).acf.phone_number}</p>
                    <p>${locations.find(location => location.id === marker.id).acf.address + ", " + locations.find(location => location.id === marker.id).acf.city + ", " + locations.find(location => location.id === marker.id).acf.provence}</p>
                </div>
    `;
            const infoWindow = new window.google.maps.InfoWindow({
                content: infoWindowContent
            });

            if (marker.id === selectedLocation) {
                marker.setIcon({
                    url: iconImg,
                    scaledSize: new window.google.maps.Size(400, 50),
                });
                handleGetDirections(locations.find(location => location.id === marker.id));
            } else if (selectedLocation === null) {
                // map.setZoom(defaultZoom); // Reset zoom level to default
                // map.panTo(defaultCenter); // Pan to the default center
                infoWindow.close(); // Close the info window
                marker.setIcon({
                    url: iconImg,
                    scaledSize: new window.google.maps.Size(55, 25),
                });
                setDirectionsResponse(null)
            }

            // Listen for the closeclick event of the info window
            infoWindow.addListener('closeclick', () => {
                // map.setZoom(defaultZoom); // Reset zoom level to default
                // map.panTo(defaultCenter); // Pan to the default center
            });

        });
    }, [selectedLocation]);

    // manage hover-over effect. 
    useEffect(() => {
        markersRef.current.forEach(marker => {
            if (marker.id === hoveredLocation) {
                marker.setIcon({
                    url: iconImg,
                    scaledSize: new window.google.maps.Size(400, 50),
                });
            } else if (marker.id !== hoveredLocation && selectedLocation !== marker.id) {
                marker.setIcon({
                    url: iconImg,
                    scaledSize: new window.google.maps.Size(55, 25),
                });
            }
        });
    }, [hoveredLocation]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ height: '100%' }}
            center={userLocation ? userLocation : defaultCenter}
            zoom={userLocation ? 12 : defaultZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: "greedy",
                styles: currentTheme === "dark" && DarkModeStyles,
            }}

        >
            {directionsResponse && (
                <DirectionsRenderer
                    directions={directionsResponse}
                    options={{
                        suppressMarkers: true,

                    }}

                />
            )}
        </GoogleMap>
    ) : (
        <div className='items-center'>
            <BlueSpinner />
        </div>
    );
};

export default GoogleMaps;