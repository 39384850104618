import React, { useEffect, useState } from 'react'
import locationService from '../../redux/locations/locationService';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import BlueSpinner from '../../components/spinnders/Spinners';
import SingleLocationGoogleMaps from '../../Sections/SingleLocationGoogleMaps';
import { FaCar, FaShoppingCart, FaMoneyBillAlt, FaCoffee, FaIceCream, FaMapMarker, FaPhone, FaTicketAlt } from 'react-icons/fa';
import { MdPropane, MdPropaneTank } from "react-icons/md";
import { Helmet } from 'react-helmet';

function LocationDetails() {

  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const currentTheme = useSelector(state => state.ui.theme);

  useEffect(() => {
    locationService.getLocationbyID(id)
      .then((fetchedLocation) => {
        setLocation(fetchedLocation);
      })
      .catch((error) => {
        console.error('Error fetching the location:', error);
      });
  }, []);

  return (
    <section id='goMarket' className={` min-h-screen pt-32
      ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>

      {location === null ? (
        <div className="w-full h-screen flex items-center justify-center">
          <BlueSpinner />
        </div>
      ) : (
        <>
          <Helmet>
            <title>Centex Gas Station - {location.acf.address + ", " + location.acf.city + ", " + location.acf.provence}</title>
            <meta name="description" content="Find a Centex Petroleum gas station using our Centex location finder. Discover the nearest 
                station offering services like diesel, regular gas, a car wash, propane tank refills, accessible fueling options, and more—all 
                conveniently displayed on our gas station map." />
          </Helmet>
          {/* Main Content: Info and Map Side-by-Side */}
          <div className="section container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 py-8">
            <div className="w-full rounded-lg overflow-hidden h-80 md:h-full">
              <SingleLocationGoogleMaps location={location} />
            </div>
            {/*Location Info */}
            <div className="space-y-3">
              <div className="flex w-full justify-between items-center">
                <div>
                  <h2 className='text-3xl font-semibold mb-2 col-span-4'>{location.title.rendered}</h2>
                  <ul className='col-span-3'>
                    {/* <li><strong>ID:</strong> {location.id}</li> */}
                    {location.acf.phone_number && <li className='flex gap-2'><FaPhone />{location.acf.phone_number}</li>}
                    <li className='flex gap-2'><FaMapMarker />{location.acf.address + ", " + location.acf.city + ", " + location.acf.provence}</li>
                  </ul>
                </div>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(location.acf.address + ', ' + location.acf.city + ', ' + location.acf.provence + ', ' + location.acf.postal_code)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-4 w-40 bg-blue-gray-700 text-white py-2 rounded-lg hover:bg-blue-gray-900 transition duration-200 text-sm text-center"
                >
                  Open in Maps
                </a>
              </div>
              <div className='flex space-x-3 p-3'>
                {/* Amenities */}
                <div className="w-full">
                  <h2 className="text-2xl font-medium mb-4">Amenities</h2>
                  <ul>
                    {location.acf.car_wash && (
                      <li className="flex items-center">
                        <FaCar className="mr-2 text-xl " /> Car Wash
                      </li>
                    )}
                    {location.acf.cstore_brand_name && (
                      <li className="flex items-center">
                        <FaShoppingCart className="mr-2 text-xl" />
                        {location.acf.cstore_brand_name}
                      </li>
                    )}
                    {location.acf.amenity_atm && (
                      <li className="flex items-center">
                        <FaMoneyBillAlt className="mr-2 text-xl " />
                        ATM Available
                      </li>
                    )}
                    {location.acf.lotto && (
                      <li className="flex items-center">
                        <FaTicketAlt className="mr-2 text-xl" />
                        Lottery
                      </li>
                    )}
                    {location.acf.coffee && (
                      <li className="flex items-center">
                        <FaCoffee className="mr-2 text-xl " />
                        {location.acf.coffee}
                      </li>
                    )}
                    {location.acf.ice_cream && (
                      <li className="flex items-center">
                        <FaIceCream className="mr-2 text-xl " />
                        {location.acf.ice_cream}
                      </li>
                    )}
                    {location.acf.propane_cylinders && (
                      <li className="flex items-center">
                        <MdPropaneTank className="mr-2 text-xl" />
                        Propane Cylinders
                      </li>
                    )}
                    {location.acf.auto_propane && (
                      <li className="flex items-center">
                        <MdPropane className="mr-2 text-xl" />
                        Auto Propane
                      </li>
                    )}
                  </ul>
                </div>

                {/* Fuel Types */}
                <div className="w-full">
                  <h2 className="text-2xl font-medium mb-4">Fuel Types</h2>
                  <ul className="">
                    {console.log(location.acf)
                    }
                    {location.acf.regular && (
                      <li className="flex items-center">
                        {/* <FaGasPump className="mr-2 text-xl" /> */}
                        Regular (87 Octane)
                      </li>
                    )}
                    {location.acf.midgrade && (
                      <li className="flex items-center">
                        {/* <FaGasPump className="mr-2 text-xl text-yellow-500" /> */}
                        Midgrade (89 Octane)
                      </li>
                    )}
                    {location.acf.premium && (
                      <li className="flex items-center">
                        {/* <FaGasPump className="mr-2 text-xl text-blue-500" /> */}
                        Premium (91 Octane)
                      </li>
                    )}
                    {location.acf.diesel && (
                      <li className="flex items-center">
                        {/* <FaGasPump className="mr-2 text-xl text-green-500" /> */}
                        Diesel
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              {/* Operating Hours */}
              <div className="rounded-lg p-2 border">
                <h2 className="text-2xl font-medium mb-4">Operating Hours</h2>
                <ul className="">
                  {Object.entries(location.acf).map(([service, value]) => {
                    if (value && service.includes("hours")) {
                      const day = service.split("_")[1].replace(/\d/g, '').slice(0, 3).toUpperCase();
                      return (
                        <li key={service} className="flex justify-between border-b">
                          <span>{day}:</span>
                          <span>{value}</span>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>

      )}
    </section>

  )
}

export default LocationDetails