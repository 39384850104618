import React from 'react'
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';
import carwashImg from "../../media/Touchless Carwash.png"
import AnimateOnScroll from '../../utilities/AnimateOnScroll';

function CarwashSection() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section id="goMarketSection" className="relative flex py-28 px-4 lg:px-0">
            <div className="flex flex-col lg:flex-row items-center justify-center w-full section mx-auto gap-16">
                {/* Image */}
                <div className="flex justify-center lg:justify-start lg:w-[619.281px]">
                    <img
                        src={carwashImg}
                        className="w-full h-auto rounded-2xl shadow-xl"
                        alt="Centex Cashback App preview"
                    />
                </div>
                {/* Text Content */}
                <AnimateOnScroll className="lg:w-[537px] flex flex-col justify-between text-center items-center lg:items-start lg:text-left relative">
                    <h3
                        className={`headerText mb-8 ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}
                    >
                        Discover our Premium Carwash Services!
                    </h3>
                    <p className={`mb-8 bodyText
                        ${currentTheme === "light" ? "centexTextColour" : ""}
                    `}>
                        Looking for a sparkling clean vehicle? Our dedicated carwash locations provide top-notch services to keep your vehicle looking its best. With Centex, you get more than just fueling—you get a full-service experience.
                    </p>
                    <HeaderLinkButton
                        link="/locations/#carwash"
                        title="Find a Centex Carwash location near you"
                        className="bg-[#4B595B] px-8 py-6"
                    />
                </AnimateOnScroll>
            </div>
        </section>

    )
}

export default CarwashSection