import { createSlice } from "@reduxjs/toolkit";
import DistanceCalculator from "../../utilities/DistanceCalculator";

// empty object to hold initial state.
const initialState = {
    filteredLocations: []
};

const filterSlice = createSlice({
    name: "filter",
    initialState,
    // Defining reducers for various filtering actions
    reducers: {
        FILTER_BY_SEARCH(state, action) {
            const { locations, search } = action.payload;
            const tempLocations = locations.filter(
                (location) => {
                    return (
                        location.title.rendered?.toLowerCase().includes(search.toLowerCase()) ||
                        location.acf.address?.toLowerCase().includes(search.toLowerCase()) ||
                        location.acf.city?.toLowerCase().includes(search.toLowerCase()) ||
                        location.acf.provence?.toLowerCase().includes(search.toLowerCase())
                        // location.acf.cstore_brand_name?.toLowerCase().includes(search.toLowerCase())
                    )
                }
            );
            state.filteredLocations = tempLocations;
        },
        FILTER_BY_FIRSTNATIONS(state, action) {
            // const { centexLocations } = action.payload;
        },
        FILTER_BY_FUELTYPE(state, action) {
            const { locations, fuelType } = action.payload;

            let tempLocations = locations?.filter((location) => {
                if (fuelType === "regular") {
                    return location.acf.regular;
                } else if (fuelType === "midgrade") {
                    return location.acf.midgrade;
                } else if (fuelType === "premium") {
                    return location.acf.premium;
                } else if (fuelType === "diesel") {
                    return location.acf.diesel;
                }
                // Add more conditions for other fuel types if needed
                return false;
            });

            state.filteredLocations = tempLocations;
        },
        FILTER_BY_CARWASH(state, action) {
            const { locations } = action.payload;

            let tempLocations = locations?.filter(
                (location) => {
                    return location.acf.car_wash
                }
            )
            state.filteredLocations = tempLocations;
        },
        FILTER_BY_CASHBACK(state, action) {
            const {locations} = action.payload;
            let tempLocations = locations.filter((location)=>{
                return location.acf.cashback
            })
            state.filteredLocations = tempLocations;
        },
        FILTER_BY_SERVICES(action, state) {
        },
        CALCULATE_DISTANCE(state, action) {
            const { locations, userLocation } = action.payload;
            // const userLocation = { Lat: 52.2296756, Lon: 21.0122287 };

            const tempLocations = locations?.map(location => {
                // Convert latitude and longitude to numbers
                const lat1 = Number(location.acf.lat);
                const lon1 = Number(location.acf.lng);
                const lat2 = Number(userLocation?.lat);
                const lon2 = Number(userLocation?.lng);

                const distance = DistanceCalculator({ lat1, lon1, lat2, lon2 });

                return { ...location, distance };
            }).filter(location => location)

            state.filteredLocations = tempLocations
        },
        SORT_BY_DISTANCE(state, action) {
            const { locations, userLocation } = action.payload;
            // const userLocation = { Lat: 52.2296756, Lon: 21.0122287 };

            const tempLocations = locations?.map(location => {
                // Convert latitude and longitude to numbers
                const lat1 = Number(location.acf.lat);
                const lon1 = Number(location.acf.lng);
                const lat2 = Number(userLocation?.lat);
                const lon2 = Number(userLocation?.lng);

                const distance = DistanceCalculator({ lat1, lon1, lat2, lon2 });

                return { ...location, distance };
            }).filter(location => location)
                .sort((a, b) => a.distance - b.distance);

            state.filteredLocations = tempLocations
        }
    },
});

// Extracting actions from the created filter slice
export const {
    FILTER_BY_SEARCH,
    FILTER_BY_FIRSTNATIONS,
    FILTER_BY_FUELTYPE,
    FILTER_BY_CARWASH,
    FILTER_BY_SERVICES,
    CALCULATE_DISTANCE,
    SORT_BY_DISTANCE,
    FILTER_BY_CASHBACK,
} = filterSlice.actions;

// Selecting filtered products from the Redux store state
export const selectFilteredLocations = (state) => state.filter.filteredLocations;

// Exporting the reducer function of the filter slice
export default filterSlice.reducer;