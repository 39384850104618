import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  theme: "light",
  language: "en"
};

// Create a slice for managing theme and language
const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    // Action to set the theme
    setTheme(state, action) {
      state.theme = action.payload;
    },
    // Action to set the language
    setLanguage(state, action) {
      state.language = action.payload;
    }
  }
});

// Export actions and reducer
export const { setTheme, setLanguage } = uiSlice.actions;
export default uiSlice.reducer;