import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import locationService from "./locationService";

// Async thunk to fetch all locations
export const getAllLocations = createAsyncThunk(
    "locations/getAll",
    async (_, thunkAPI) => {
        try {
            // Fetch all locations from the locationService
            const response = await locationService.getAllLocations();
            return response.sort((a, b) => a.title.rendered.localeCompare(b.title.rendered)); // Return the fetched locations
        } catch (error) {
            // Handle errors during the async operation
            const message = (error.response && error.response.data && error.response.data.message)
                || error.message || error.toString();
            console.error(message);
            // Rethrow the error to let Redux Toolkit handle it
            throw new Error(message);
        }
    }
);

// Async thunk to fetch a single location by ID
export const getLocationById = createAsyncThunk(
    "locations/getById",
    async (locationId, thunkAPI) => {
        try {
            // Fetch a single location by ID from the locationService
            const response = await locationService.getLocationById(locationId);
            return response; // Return the fetched location
        } catch (error) {
            // Handle errors during the async operation
            const message = (error.response && error.response.data && error.response.data.message)
                || error.message || error.toString();
            console.error(message);
            // Rethrow the error to let Redux Toolkit handle it
            throw new Error(message);
        }
    }
);

// Async thunk to add a new location
export const addLocation = createAsyncThunk(
    "locations/add",
    async (newLocation, thunkAPI) => {
        try {
            // Add a new location using the locationService
            const response = await locationService.addLocation(newLocation);
            return response; // Return the added location
        } catch (error) {
            // Handle errors during the async operation
            const message = (error.response && error.response.data && error.response.data.message)
                || error.message || error.toString();
            console.error(message);
            // Rethrow the error to let Redux Toolkit handle it
            throw new Error(message);
        }
    }
);

// Async thunk to delete a location by ID
export const deleteLocationById = createAsyncThunk(
    "locations/deleteById",
    async (locationId, thunkAPI) => {
        try {
            // Delete a location by ID using the locationService
            await locationService.deleteLocationById(locationId);
            return locationId; // Return the ID of the deleted location
        } catch (error) {
            // Handle errors during the async operation
            const message = (error.response && error.response.data && error.response.data.message)
                || error.message || error.toString();
            console.error(message);
            // Rethrow the error to let Redux Toolkit handle it
            throw new Error(message);
        }
    }
);

const locationsSlice = createSlice({
    name: "locations",
    initialState: {
        allLocations: [],
        singleLocation: null,
        isError: false,
        isLoading: false,
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLocations.pending, (state) => {
                // Set loading state to true and reset isError state when fetching all locations starts
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getAllLocations.fulfilled, (state, action) => {
                // Set loading state to false and update all locations when fetching all locations succeeds
                state.isLoading = false;
                state.allLocations = action.payload;
            })
            .addCase(getAllLocations.rejected, (state) => {
                // Set loading state to false and isError state to true when fetching all locations fails
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getLocationById.pending, (state) => {
                // Set loading state to true and reset isError state and singleLocation when fetching a single location starts
                state.isLoading = true;
                state.isError = false;
                state.singleLocation = null;
            })
            .addCase(getLocationById.fulfilled, (state, action) => {
                // Set loading state to false and update singleLocation when fetching a single location succeeds
                state.isLoading = false;
                state.singleLocation = action.payload;
            })
            .addCase(getLocationById.rejected, (state) => {
                // Set loading state to false and isError state to true when fetching a single location fails
                state.isLoading = false;
                state.isError = true;
            });
    },
});

// Export the reducer
export default locationsSlice.reducer;

// Selector to get all locations from state
export const selectAllLocations = (state) => state.locations.allLocations;

// Selector to get a single location from state
export const selectSingleLocation = (state) => state.locations.singleLocation;
