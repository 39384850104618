import React from 'react';
import { FaFacebook, FaTwitter, FaHome, FaMapMarkedAlt } from 'react-icons/fa';
import { NavLink} from 'react-router-dom';

function SocialMediaLinks() {
    return (
        <ul className='flex gap-x-4 transition-all'>
            <li className='hover:scale-125 hover:centexTextColour'>
                <NavLink
                    to='/'
                    key='/home'
                >
                    <FaHome />
                </NavLink>
            </li>
            <li className='hover:scale-125 hover:centexOrangeText'>
                <a href="https://m.facebook.com/profile.php?id=1721769118103296&_rdr" >
                    <FaFacebook />
                </a>
            </li>
            <li className=' hover:scale-125 hover:centexOrangeText'>
                <a href="https://twitter.com/search?f=tweets&q=centex%20petroleum&src=typd" >
                    <FaTwitter/>
                </a>
            </li>
            <li className=' hover:scale-125 hover:centexOrangeText'>
                <a href="https://maps.app.goo.gl/2PcQPbAhPDmadcMd9" >
                    <FaMapMarkedAlt />
                </a>
            </li>
        </ul>
    )
}

export default SocialMediaLinks;