import HomeCarousel from "../Sections/HomePageSections/HomeCarousel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import BlueSpinner from "../components/spinnders/Spinners";
import HomePagePromoService from "../redux/promos/PromoService";
import useScrollToHash from "../utilities/useScrollToHash";
import CentexCashbackApp from "../Sections/HomePageSections/CentexCashbackApp";
import GoMarketQuickServe from "../Sections/HomePageSections/GoMarketQuickServe";
import FuelProgremSection from "../Sections/HomePageSections/FuelProgremSection";
import FirstNationsSection from "../Sections/HomePageSections/FirstNationsSection";
import CarwashSection from "../Sections/HomePageSections/CarwashSection";
import HeaderLinkButton from "../components/ui/HeaderLinkButton";
import AnimateOnScroll from "../utilities/AnimateOnScroll";

function Home() {
    useScrollToHash();
    const [promos, setPromos] = useState([]);
    const currentTheme = useSelector(state => state.ui.theme);

    useEffect(() => {
        const fetchPromos = async () => {
            try {
                if (promos?.length === 0) {
                    setPromos(await HomePagePromoService.getAllPromos());
                }
            } catch (e) {
                console.error('Error Fetching Promos')
            }
        }
        fetchPromos();
    })

    return (
        <section id="home" className={`mx-auto min-h-screen
        ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            {promos.length === 0 ? (
                <div className="w-full h-screen flex items-center justify-center">
                    <BlueSpinner />
                </div>
            ) : (
                <HomeCarousel allPromos={promos} />
            )}

            {/* Sections after the home page slider */}
            <div className="flex flex-col">
                {/* <LoyaltyProgramsDetails/> */}
                <CentexCashbackApp />
                {/* 150 Centex Locations section */}
                <div className={`px-6 py-16 text-center ${currentTheme === "light"
                    ? "centexTextColour"
                    : ""
                    }`}>
                    <h3 className='headerText font-bold section'>Explore over 150 Centex Locations Nationwide!</h3>
                    <AnimateOnScroll>
                        <p className='pt-5 pb-10 px-20 section bodyText'>
                            Experience convenience like never before with Centex. Our extensive network of over 150 locations
                            across Canada ensures that you're always close to reliable fueling and exceptional services.
                            Whether you're on the road or close to home, Centex is here to meet all your fueling needs.
                        </p>
                        <HeaderLinkButton
                            link="/locations/"
                            title="Find the Centex nearest to you"
                            className="text-[16px] py-3 px-[40px] font-bold"
                        />
                    </AnimateOnScroll>
                </div>
                <FuelProgremSection />
                <GoMarketQuickServe showbutton={true} />
                <FirstNationsSection />
                <CarwashSection />
            </div>

        </section >
    );
}

export default Home;