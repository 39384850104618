import React from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";

const Search = ({ value, placeholder, onChange, onClear }) => {
  const currentTheme = useSelector(state => state.ui.theme);

  return (
    <div className={`flex items-center gap-2 px-3 w-full rounded-full border transition-all
    ${currentTheme === "light" ? "centexTextColour" : " text-white border-blue-gray-500"}`}
    >
      <FaSearch className="text-xl my-auto" />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`p-2 w-full bg-transparent focus:outline-none 
        ${currentTheme === "light" ? "text-black placeholder-blue-gray-600" : "text-blue-gray-100 placeholder-blue-gray-100"}`}
      />
      <button className="p-1 hover:scale-110 font-thin" onClick={onClear}>
        Clear
      </button>
    </div>

  );
};

export default Search;