import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../redux/UI/uiSlice';
import { IoSunnyOutline } from "react-icons/io5";
import { FiMoon } from "react-icons/fi";

function ThemeButton() {
    const dispatch = useDispatch();
    const currentTheme = useSelector(state => state.ui.theme);

    const handleThemeChange = () => {
        const newTheme = currentTheme === 'light' ? "dark" : "light"
        dispatch(setTheme(newTheme))
    }
    return (
        <button
            className={`flex items-center justify-center transition-all rounded-full shadow-md w-9 h-9 text-lg active:shadow-inner
            ${currentTheme === "light" ? "bg-gray-100 text-gray-800 hover:bg-gray-200" : "bg-[#0f4f80] text-white hover:bg-[#0A3351]"}`}
            onClick={handleThemeChange}
            title='Change Theme'
        >
            {currentTheme === "light" ? <IoSunnyOutline /> : <FiMoon />}
        </button>

    )
}

export default ThemeButton