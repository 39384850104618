import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import { MapsDarkModeStyles } from '../utilities/MapsDarkModeStyles';
import { useSelector } from 'react-redux';
import iconImg from "../media/map_pin.svg";
import BlueSpinner from '../components/spinnders/Spinners';

const SingleLocationGoogleMaps = ({ location }) => {

    const DarkModeStyles = MapsDarkModeStyles;
    const position = { lat: parseFloat(location.acf.lat), lng: parseFloat(location.acf.lng) }
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const [map, setMap] = useState(null);
    const markersRef = useRef([]);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const currentTheme = useSelector(state => state.ui.theme);
    const onLoad = React.useCallback(mapInstance => setMap(mapInstance), []);
    const onUnmount = React.useCallback(() => setMap(null), []);
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey });

    //Mark all locations
    useEffect(() => {
        if (!isLoaded || !map) return;

        const clearMarkers = () => {
            markersRef.current.forEach(marker => marker.setMap(null));
            markersRef.current = [];
        };

        const addMarkers = () => {

            const marker = new window.google.maps.Marker({
                position: { lat: parseFloat(location.acf.lat), lng: parseFloat(location.acf.lng) },
                map: map,
                title: location.title.rendered,
                id: location.id,
                icon: {
                    url: iconImg,
                    scaledSize: new window.google.maps.Size(100, 50)
                },
            });
            markersRef.current.push(marker);
        };

        clearMarkers();
        addMarkers();

        return () => clearMarkers();
    }, [ map]);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ height: '100%' }}
            center={position}
            zoom={18}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: "",
                styles: currentTheme === "dark" && DarkModeStyles,
            }}

        >
            {directionsResponse && (
                <DirectionsRenderer
                    directions={directionsResponse}
                    options={{
                        suppressMarkers: true,

                    }}

                />
            )}
        </GoogleMap>
    ) : (
        <div className='items-center'>
            <BlueSpinner />
        </div>
    );
}

export default SingleLocationGoogleMaps;