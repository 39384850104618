import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PromoService from "./PromoService";

// Async thunk to fetch all promos
export const getAllPromos = createAsyncThunk(
    "Promos/getAll",
    async (_, thunkAPI) => {
        try {
            // Fetch all promos from the promo service
            const response = await PromoService.getAllPromos();
            return response; // Access response data
        } catch (error) {
            // Handle errors during the async operation
            const message = error.response?.data?.message || error.message || error.toString();
            console.error("Error fetching promos:", message); // Log error message
            throw error; // Throw the error for Redux Toolkit to handle
        }
    }
);

const promoSlice = createSlice({
    name: "promos",
    initialState: {
        allPromos: [],
        singlePromo: null,
        isError: false,
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllPromos.pending, (state) => {
                // Update loading and error states when fetching promos is pending
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getAllPromos.fulfilled, (state, action) => {
                // Update loading state to false and update all promos when fetching all promos succeeds
                state.isLoading = false;
                state.isError = false; // Reset error state
                state.allPromos = action.payload;
            })
            .addCase(getAllPromos.rejected, (state) => {
                // Update loading state to false and isError state to true when fetching all promos fails
                state.isLoading = false;
                state.isError = true;
            })
    }
})

// Export the reducer
export default promoSlice.reducer;

// Export selectAllPromos selector function
export const selectAllPromos = (state) => state.promos.allPromos;