import { configureStore } from "@reduxjs/toolkit";
import locationSlice from "./locations/locationSlice";
import PromoSlice from "./promos/PromoSlice";
import UiSlice from "./UI/uiSlice";
import filterSlice from "./Filter/filterSlice";
import GoMarketSlice from "./GoMarket/GoMarketSlice";

export const reduxStore = configureStore({
    reducer:{
        locations: locationSlice,
        filter: filterSlice,
        promos: PromoSlice,
        goMarket: GoMarketSlice,
        ui: UiSlice,
    },
});