import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

function HeaderLinkButton({ title, link, className }) {
    const currentTheme = useSelector(state => state.ui.theme);

    const activeClass = currentTheme === 'light'
        ? 'bg-[#F99D24]'
        : 'bg-[#F99D24] ';

    const inactiveClass = currentTheme === 'light'
        ? 'cextexBackgroundColour text-white'
        : 'bg-gray-300 centexTextColour';

    return (
        <NavLink
            to={link}
            key={link}
            className={({ isActive }) =>
                `${isActive ? activeClass : inactiveClass} transition-all text-[16px] font-bold py-3 px-16 centexTextColour rounded-xl hover:scale-105 active:scale-100 hover:shadow-lg hover:bg-[#F99D24] hover:text-[#0C416A] text-center ${className}`
            }
        >
            {title}
        </NavLink>
    );
}

export default HeaderLinkButton;
