import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BlueSpinner from '../components/spinnders/Spinners';
import GoMarketService from '../redux/GoMarket/GoMarketService';
import ImageSlider from '../components/ui/ImageSlider';
import QuickServeRestaurents from "../Sections/QuickServeRestaurents"
import useScrollToHash from '../utilities/useScrollToHash';
import goMarketHeroImg from '../media/GoMarketQuickServe/GoMarketHeroImg.svg'
import goMarketWhite from "../media/GoMarketQuickServe/GoMarket White.png"
import GoMarketQuickServe from '../Sections/HomePageSections/GoMarketQuickServe';

function GoMarket() {
  const [content, setContent] = useState([])//useSelector(selectAllGoMarketContent)
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();

  useEffect(() => {
    const fetchContent = async () => {
      if (content.length === 0) {
        try {
          const data = await GoMarketService.getAllGoMarketContent();
          setContent(data);
        } catch (error) {
          console.error('Error fetching content:', error);
        }
      }
    };

    fetchContent();
  }, []);

  return (
    <section id='goMarket' className={`min-h-screen
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      {content.length === 0 ? (
        <div className="w-full h-screen flex items-center justify-center">
          <BlueSpinner />
        </div>
      ) : (
        <>
          {/* Hero Section */}
          <div className='h-screen relative flex items-center'>
            <img
              alt='Semi truck driving on highway through mountains'
              src={goMarketHeroImg}
              className='object-cover h-screen w-screen absolute'
            />
            <div className={`absolute inset-0 bg-[#0202027c] opacity-60 ${currentTheme === "" && "brightness-50"}`}></div>
            <div className='section w-full justify-center z-10 text-center pt-5'>
              <h1 className={`relative lg:text-7xl text-4xl font-bold ${currentTheme === "light" ? "text-white" : "centexOrangeText"}`}>
                Fast or Fresh, our stores have everything you need
              </h1>
              {/* <img src={goMarketWhite} className='w-80 px-10 py-5 centexOranceBg rounded-full'/> */}
            </div>
          </div>
          <GoMarketQuickServe/>
          <section className="section relative py-20 md:flex md:items-start md:space-x-10">
            <div className="w-full md:w-1/2 text-center md:text-left mb-10 md:mb-0">
              <h2 className={`text-5xl font-extrabold leading-tight ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
                {content[1]?.title.rendered}
              </h2>
              <p className="text-lg my-8">
                {content[1]?.acf.p1}
              </p>
            </div>
            <div className={`w-full md:w-1/2 text-center ${currentTheme === "light" ? "backdrop-brightness-90 border-gray-300 bg-[#ffffff8e]" : "backdrop-brightness-75 border-gray-700 bg-[#093353]"}`}>
              <ImageSlider />
            </div>
          </section>
          {/* <div className={`section mb-8 py-6 p-6 rounded-lg shadow-lg ${currentTheme === "light" ? "bg-[#ffffff8e] border-gray-300" : "bg-[#09335394] border-gray-700"}`}>
            <h2 className={`text-3xl font-bold mb-4 ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
              {content[1]?.acf.sub_heading}
            </h2>
            <p className="mb-4">{content[1]?.acf.sub_heading_copy}</p>
            <div className='flex-col'>
              <ul className="list-disc pl-5">
                {content[1]?.acf.sub_heading_copy2.split(',').map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            </div>
          </div>
          <QuickServeRestaurents /> */}
        </>
      )}
    </section>
  );
}

export default GoMarket;
