import axios from "axios";

const BASE_URL = process.env.REACT_APP_WP_RESTAPI_ENDPOINT;

const getAllGoMarketContent = async () => {
    try{
        const response = await axios.get(BASE_URL +"/go-market-content/");
        return response.data;
    } catch (error){
        console.error('Error fetching Go Market Content', error);
        throw new Error('Failed to fetch go market content')
    }
};

const GoMarketService = { 
    getAllGoMarketContent
}

export default GoMarketService;
