import React from 'react'
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';
import mountainRoadImg from "../media/AboutUs/mountainRoad.jpg"
import centexLocationImg from "../media/AboutUs/centexLocationImage.jpg"
import oldLocationPic from "../media/AboutUs/OldLocationPic.png"
import gaspumpPic from "../media/AboutUs/gasPump.png"
import AnimateOnScroll from '../utilities/AnimateOnScroll';

function About() {
    const currentTheme = useSelector(state => state.ui.theme);
    useScrollToHash();
    return (
        <section className={`min-h-screen
        ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
            {/* Hero Section */}
            <div className='h-screen relative flex items-center'>
                <img
                    alt='Semi truck driving on highway through mountains'
                    src={mountainRoadImg}
                    className='object-cover h-screen w-screen absolute'
                />
                <div className={`absolute inset-0 bg-[#000000]  ${currentTheme === "light" ? "opacity-10" : "opacity-50"}`}></div>
                <div className='section relative w-full h-full z-10 text-center pt-5 flex flex-col justify-center'>
                    <h1 className={`lg:text-7xl md:text-6xl text-5xl font-bold mb-28 ${currentTheme === "light" ? "text-white" : "centexOrangeText"}`}>
                        100% Canadian owned & operated <br />
                        100% independent
                    </h1>
                </div>
            </div>
            {/* How we purchase the fuel */}
            <div className={`flex flex-col lg:flex-row gap-14 py-20 items-center lg:items-start section`}>
                <AnimateOnScroll className="lg:w-1/2 flex flex-col gap-y-6 my-auto px-4 lg:px-0">
                    <h3 className={`headerText font-bold  ${currentTheme === "light" ? "centexGreyText" : "centexOrangeText"}`}>How we purchase the fuel helps us provide our customers with great prices.</h3>
                    <p className={`subDescriptionText py-8 font-semibold ${currentTheme === "light" ? "centexTextColour" : ""}`}>
                        At Centex, we keep fuel prices competitive, offer convenient locations, and deliver services for people on the go—making us a refreshing alternative to the big brands.
                        <br />
                        <br />
                        As an independent chain, we're free to shop around daily and find the best deals on fuel, so we can pass those savings on to you.
                    </p>
                </AnimateOnScroll>

                <div className=" flex justify-center lg:justify-start">
                    <img src={centexLocationImg} className="w-full max-w-2xl" alt="GoMarket Store" />
                </div>
            </div>
            {/* Since 1986 section */}
            <div className='relative items-center h-[50vh] md:h-full'>
                <img
                    src={oldLocationPic}
                    alt='Centex location in 1986'
                    className='h-[75vh] object-cover w-full' // Full width on small screens
                />
                <div className={`absolute inset-0 bg-[#F99D24] opacity-30 ${currentTheme === "" && "brightness-50"}`}></div>
                <AnimateOnScroll className='absolute inset-0'>
                    <div className='section relative text-black rounded-2xl'>
                        <p className='mt-20 w-full sm:w-[600px] px-4 sm:px-7 py-5 sm:py-7 lightTransparentBg subDescriptionText rounded-2xl font-semibold shadow-xl'>
                            Since 1986, Centex has been serving communities with a commitment to respect, accountability, teamwork, and a sense of fun. Our values are the foundation of our success and drive our continued growth every day.
                        </p>
                    </div>
                </AnimateOnScroll>
            </div>
            {/* What is in your tank */}
            <div className={`px-6 py-12 text-center`}>
                <h3 className='subHeadderText py-5 section centexOrangeText'>What's in your Tank?</h3>
                <p className='py-5 section descriptionText'>
                    At Centex, we're committed to bringing you high-quality, Canadian-refined fuel designed for all
                    driving conditions—especially the tough Canadian winters. Our fuel meets or exceeds all federal
                    and provincial standards, so you can trust you're fueling up with quality.
                </p>
            </div>
            {/* Premiup fuel and desel fuel */}
            <div className={`drop-shadow-2xl ${currentTheme === "light"
                ? "bg-gradient-to-r from-[#ffffff00] to-[#b9d6e977]"
                : "bg-gradient-to-r from-[#07284100] to-[#072841]"
                }`}>
                <div className={`flex flex-col lg:flex-row gap-8 pt-6 pb-12 items-center lg:items-start section`}>
                    {/* Image Container */}
                    <img
                        src={gaspumpPic}
                        className="w-1/2"
                        alt="GoMarket Store"
                    />
                    {/* Text Content */}
                    <AnimateOnScroll className={`lg:w-1/2 pl-0 lg:pl-8 pt-10 md:pt-0 text-center lg:text-left descriptionText 
                    ${currentTheme === "light" ? "centexTextColour" : ""}`}>
                        <p className="mb-6">
                            <strong className='font-bold'>Premium Fuel:</strong> Enjoy high-performance fuel with an octane rating of 91 or higher, just like major retailers but at a better price! Compare and see the difference in your wallet.
                        </p>
                        <p className="mb-6">
                            <strong className='font-bold'>Diesel:</strong> We offer ultra-low sulfur diesel for all on-road diesel vehicles, from passenger cars to commercial trucks. Our clean fuel is free from additives that can clog injectors or harm diesel engines, ensuring smoother, more reliable performance.
                        </p>
                        <p>
                            Fuel up with confidence at Centex!
                        </p>
                    </AnimateOnScroll>
                </div>
            </div>
        </section>
    )
}

export default About
