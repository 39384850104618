import React from 'react';
import { useSelector } from 'react-redux';

function FAQ() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section>
            ""
        </section>
    );
}

export default FAQ;
