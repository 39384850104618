import React from 'react'
import centexBlue from "../media/CentexLogo.svg"
import centexWhite from "../media/Centex_White.png"
import { useSelector } from 'react-redux';

function Logo() {
    const currentTheme = useSelector(state => state.ui.theme);
    return (
        <div>
            <img
                src={currentTheme === "light" ? centexBlue : centexWhite}
                className='h-7 md:h-11'
            />
        </div>
    )
}

export default Logo