import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';
import truckImg from '../media/FuelProgram/Semi truck driving on hiway through mountains 1.svg'
import fuleCardImg from '../media/FuelProgram/Fuel Pass card.svg'
import { AiOutlineFileSearch } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { TiClipboard } from "react-icons/ti";
import { VscGraph } from "react-icons/vsc";
import { LuShieldCheck } from "react-icons/lu";
import { TbClockDollar } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import HeaderLinkButton from '../components/ui/HeaderLinkButton';
import locationImg from "../media/FuelProgram/Bearspaw_7 1.svg"
import fleetTrucksImg from "../media/FuelProgram/fleetTrucks.png"
import AnimateOnScroll from '../utilities/AnimateOnScroll';

function FuelProgram() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`min-h-screen relative
    ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      {/* Hero Section */}
      <div className='h-screen relative flex items-center'>
        <img
          alt='Semi truck driving on highway through mountains'
          src={truckImg}
          className='object-cover h-screen w-screen absolute'
        />
        <div className={`absolute inset-0 bg-[#005DA4] opacity-60 ${currentTheme === "" && "brightness-50"}`}></div>
        <div className='section lg:text-left w-full h-2/3 z-10 text-center pt-40'>
          <h1 className={`relative lg:text-6xl text-4xl font-bold ${currentTheme === "light" ? "text-white" : "centexOrangeText"}`}>
            A better way to fuel your business
          </h1>
          <p className='lg:text-4xl text-2xl lg:py-12 py-6 text-white'>
            Convenient solutions for your fuel requirements
          </p>
          <img alt='Centex Fuel Pass Card' src={fuleCardImg}
            className='lg:w-80 w-40 mx-auto lg:mx-0'
          />
        </div>
      </div>
      {/* Fleet Features section */}
      <div className='section py-10'>
        <h2 className={`headerText text-center pt-14 md:px-40 ${currentTheme === "light" ? "centexGreyText" : "centexOrangeText"}`}>
          Control Spending, reduce fraud & save time with the Centex Fuel Pass.
        </h2>
        <AnimateOnScroll className="flex flex-wrap justify-center items-center text-center font-bold gap-5 py-16">
          {[
            { icon: <AiOutlineFileSearch />, text: "Controlled Spending" },
            { icon: <GrLocation />, text: "150+ locations across Canada" },
            { icon: <TiClipboard />, text: "Easily identify fuel expenses" },
            { icon: <VscGraph />, text: "Customized Reporting" },
            { icon: <LuShieldCheck />, text: "Security to reduce fraud" },
            { icon: <TbClockDollar />, text: "Save Time & Money" },
            { icon: <FiPhone />, text: "Dedicated Support Team" },
          ].map(({ icon, text }, index) => (
            <div key={index} className="flex flex-col items-center gap-y-2 w-[150px]">
              <div className="text-4xl sm:text-5xl text-centexOrange">{icon}</div>
              <p className="text-lg centexOrangeText">{text}</p>
            </div>
          ))}
        </AnimateOnScroll>
      </div>
      {/* Greay section */}
      <div className="bg-[#4B595B] w-full py-10">
        <div className="section flex flex-col lg:flex-row gap-6 lg:gap-10 items-center justify-center text-center lg:text-left ">
          <HeaderLinkButton link={"/"} title={"Apply For Centex Fuel Pass"} className={"text-lg sm:text-xl py-4 px-14 centexOranceBg font-bold text-white"} />
          <p className="subHeadderText text-white">
            Have questions? <a className="font-semibold" href="tel:18332236839">1(833) 223-6839</a>
          </p>
        </div>
      </div>
      {/* Fleet and location section */}
      <div className="section flex flex-col lg:flex-row gap-8 py-20 lg:py-20 items-center lg:items-start section">
        <div className="flex justify-center lg:justify-start ">
          <img src={locationImg} className="w-full max-w-md" alt="GoMarket Store" />
        </div>
        {/* Text Content */}
        <AnimateOnScroll className="lg:w-1/2 text-center lg:text-left mt-8 lg:mt-0">
          <h2
            className={`subHeadderText mb-8 font-bold
                  ${currentTheme === "light"
                ? "centexOrangeText"
                : "centexOrangeText"
              }`}
          >
            Created for fleets of all sizes & accepted at every single Centex location
          </h2>
          <p className="subDescriptionText mb-6">
            Have a fleet of vehicles and are looking for a convenient solution to your fuel requirements?
            <br />
            <br />
            Whether you're fuelling cars for your sales reps, delivery vans, or a fleet of transport trucks,
            the Centex FuelPass has you covered.
          </p>
        </AnimateOnScroll>
      </div>
      {/* Third party Fleet Account section */}
      <div className={`px-6 py-12 lg:py-20 ${currentTheme === "light"
        ? "bg-gradient-to-br from-[#9f9f9f49] to-[#f99d2427] centexGreyText"
        : "bg-gradient-to-br from-[#07284100] to-[#072841]"
        }`}>
        <h2
          className={`headerText mb-4 text-center 
            ${currentTheme === "light" ? "centexGreyText" : "centexOrangeText"}`}
        >
          Third party corporate fleet accounts
        </h2>
        <div className="flex flex-col lg:flex-row gap-8 pt-12 items-center lg:items-start px-6 lg:px-12 section">
          {/* Text Content */}
          <AnimateOnScroll className="lg:w-1/2 ">
            <h3 className="descriptionText font-semibold text-center lg:text-left">
              If you already have a 3rd party corporate fleet account, we accept the following providers:
            </h3>
            <ul className="subDescriptionText py-6 lg:py-10 pl-10 list-disc">
              <li>ARI & Federal Government</li>
              <li>Corp-Rate</li>
              <li>Foss National Leasing</li>
              <li>GE Capital (Element Fleet)</li>
              <li>Jim Pattison Lease</li>
              <li>PH&H (Element Fleet)</li>
              <li>Element Fleet Services</li>
              <li>WEX</li>
            </ul>
          </AnimateOnScroll>
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-start rounded-lg">
            <img src={fleetTrucksImg} className="w-full scale-110 rounded-lg" alt="GoMarket Store" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FuelProgram;
