import React from 'react'
import { useSelector } from 'react-redux';
import HeaderLinkButton from '../../components/ui/HeaderLinkButton';
import firstNationsimg from "../../media/FirstNations/FirstNationsGasStationWithTextImg.png"
import AnimateOnScroll from '../../utilities/AnimateOnScroll';

function FirstNationsSection() {
    const currentTheme = useSelector(state => state.ui.theme);

    return (
        <section id="loyaltyProgramsSection" className={`bg-opacity-50 py-24
            ${currentTheme === "light"
                ? "bg-gradient-to-br from-[#ffffff00] to-[#b9d6e986]"
                : "bg-gradient-to-br from-[#07284100] to-[#072841]"
            }`} >
            <div className="section relative">
                <div className="flex flex-col gap-y-10 lg:gap-y-16">
                    <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-y-10 items-center gap-x-6">
                        <div className="text-center lg:text-left lg:w-1/2 flex flex-col justify-between h-full gap-14 pr-10">
                            <h2
                                className={`headerText flex-grow
                                ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}
                            >
                                Our Partnership with First Nations throughout Canada
                            </h2>
                            <p className={`bodyText text-center lg:text-left 
                                ${currentTheme === "light"
                                    ? "centexTextColour"
                                    : ""
                                }
                                `}>
                                Centex empowers First Nations through partnerships focused on community ownership, reliable fuel supply,
                                project management, and ongoing support, fostering growth and shared success.
                            </p>
                            <div>
                                <HeaderLinkButton link={"/firstnations/"} title={"Learn More"} className="bg-[#4B595B]" />
                            </div>
                        </div>

                        <div className="lg:w-[658.158px] flex justify-center lg:justify-end">
                            <img
                                src={firstNationsimg} alt="Centex-First Nations gas station"
                                className="rounded-3xl shadow-lg w-full h-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default FirstNationsSection