import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Locations from './pages/Location pages/Locations';
import ScrollToTopButton from './components/ui/ScrollToTopButton';
import LocationDetails from './pages/Location pages/LocationDetails';
import CashBack from './pages/CashBack';
import FirstNations from './pages/FirstNations';
import GoMarket from './pages/GoMarket';
import FuelProgram from './pages/FuelProgram';
import { SpeedInsights } from "@vercel/speed-insights/react"
import About from './pages/About';
import Opportunities from './pages/Oportunities';
import Communities from './pages/Communities';
import History from './pages/History';
import { setLanguage, setTheme } from './redux/UI/uiSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import FAQ from './pages/FAQandTerms/FAQ';

function App() {
  const dispatch = useDispatch();

  // Detect user's preferred language
  useEffect(() => {
    const userLanguage = window.navigator.userLanguage || window.navigator.language;
    if (userLanguage.startsWith("fr")) {
      dispatch(setLanguage("fr"));
    } else {
      dispatch(setLanguage("en")); // Default language
    }
  }, [dispatch]);

  // This effect listens for changes to the user's preferred color scheme (dark or light) 
  // and updates the application theme accordingly.
  useEffect(() => {
    // It initially sets the theme based on the current preference and then sets up event listeners
    // to detect and respond to any changes in the preference.
    // The event listeners are cleaned up when the component unmounts to avoid memory leaks.
    const mediaQueryDark = window.matchMedia('(prefers-color-scheme: dark)');
    const mediaQueryLight = window.matchMedia('(prefers-color-scheme: light)');

    // Function to update the theme
    const updateTheme = () => {
      if (mediaQueryDark.matches) {
        dispatch(setTheme("dark"));
      } else {
        dispatch(setTheme("light")); // Default theme
      }
    };

    // Initial theme setting
    updateTheme();

    // Add listeners to detect changes
    mediaQueryDark.addEventListener('change', updateTheme);
    mediaQueryLight.addEventListener('change', updateTheme);

    // Clean up listeners on unmount
    return () => {
      mediaQueryDark.removeEventListener('change', updateTheme);
      mediaQueryLight.removeEventListener('change', updateTheme);
    };
  }, [dispatch]);

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gomarket/" element={<GoMarket />} />
          <Route path="/firstnations/" element={<FirstNations />} />
          <Route path="/About/" element={<About />} />
          <Route path="/About/history/" element={<History />} />
          <Route path="/About/Communities/" element={<Communities />} />
          <Route path="/Opportunities/" element={<Opportunities />} />
          <Route path="/locations/" element={<Locations />} />
          <Route path="/locations/:id" element={<LocationDetails />} />
          <Route path="/cashback/" element={<CashBack />} />
          <Route path="/fuelprogram/" element={<FuelProgram />} />
          <Route path="/cashback-faq/" element={<FAQ/>} />
          <Route path="/Cashback-terms-of-service/" element={""} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
        <SpeedInsights />
      </Router>
    </>
  );
}

export default App;
