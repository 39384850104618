import React from 'react';
import { useSelector } from 'react-redux';
import useScrollToHash from '../utilities/useScrollToHash';
import familyDrivingImg from '../media/cashback/familyDriving.png'
import cashbackCard from '../media/cashback/Cashback Card.svg'
import AnimateOnScroll from '../utilities/AnimateOnScroll';
import AppStoreButtons from "../media/CashbackApp/AppStoreButtons.svg"
import AppHomePage from "../media/CashbackApp/AppHomeScreen.svg"
import HeaderLinkButton from '../components/ui/HeaderLinkButton';
import pumpingGasImg from "../media/cashback/pumpingGass.png"

function CashBack() {
  const currentTheme = useSelector(state => state.ui.theme);
  useScrollToHash();
  return (
    <section className={`min-h-screen
      ${currentTheme === "light" ? "lightTheme" : "darkTheme"}`}>
      {/* Hero Section */}
      <div className="h-screen flex flex-col">
        <img
          alt="Semi truck driving on highway through mountains"
          src={familyDrivingImg}
          className="object-cover h-screen w-screen absolute"
        />
        <div className={`absolute inset-0 h-screen bg-[#000000] ${currentTheme === "light" ? "opacity-10" : "opacity-50"}`}></div>

        <div className="section z-10 text-center md:text-right pt-96 md:pt-44 w-full">
          <h1 className={`text-5xl font-extrabold ${currentTheme === "light" ? "centexTextColour" : "centexOrangeText"}`}>
            Fuel Up
            <br />
            Save Instantly
            <br />
            Earn Cashback
          </h1>
        </div>
      </div>
      {/* Cashback card */}
      <div className="section flex flex-col lg:flex-row gap-20 py-20 lg:py-20 items-center lg:items-start">
        <div className="flex justify-center lg:justify-start w-3/5">
          <img src={cashbackCard} className="w-full rounded-lg" alt="Cashback card" />
        </div>

        {/* Text Content */}
        <AnimateOnScroll className=" text-center lg:text-left mt-8 lg:mt-0">
          <h2
            className={`headerText mb-8 font-bold
                  ${currentTheme === "light"
                ? "centexTextColour"
                : "centexOrangeText"
              }`}
          >
            Get the most out of your fuel stops with us, because every trip should give something back.
          </h2>
          <p className="subDescriptionText mb-6">
            Get Started with Centex Cashback by picking up a Centex Cashback Card at participating stores
            or download the Centex Cashback app via the links below for a virtual card.
          </p>
        </AnimateOnScroll>
      </div>
      {/* Signup for cashabck */}
      <div className={`px-6 py-12 text-center ${currentTheme === "light"
        ? "bg-gradient-to-br from-[#b9d6e94f] to-[#5aba4717]"
        : "bg-gradient-to-br from-[#07284100] to-[#072841]"
        }`}>
        <h3 className='headerText py-5'>Sign up for Centex Cashback</h3>
        <div className='flex gap-x-3 justify-center descriptionText'>
          <HeaderLinkButton title={"Create an account"} link={"/createAccount/"} />
          <HeaderLinkButton title={"Register your card"} link={"/registerAccount/"} />
        </div>
        <p className='py-5'>Already registered? <a className='text-blue-900' href=''>Login to your account here.</a></p>
      </div>
      {/* How It Works */}
      <div className={`flex flex-col lg:flex-row gap-5 py-16 items-center lg:items-start section
            ${currentTheme === "light" ? "text-[#005DA4]" : "centexOrangeText"}
      `}>
        <AnimateOnScroll className="lg:w-1/2 flex flex-col gap-y-6 my-auto px-4 lg:px-0">
          <h3 className="subHeadderText font-bold ">How to use your card:</h3>
          <ul className={`bodyText descriptionText list-disc text-left px-5 ${currentTheme === "light" ? "text-black" : "text-white"}`}>
            <li>At the pump: Swipe your physical Centex Cashback Card before your payment card.</li>
            <li>In-store: Show your Cashback card or the app barcode to the cashier.</li>
          </ul>

          <h3 className="subHeadderText font-bold">How to earn cashback:</h3>
          <ul className={`bodyText list-disc text-left px-5 ${currentTheme === "light" ? "text-black" : "text-white"}`}>
            <li>
              2¢ cashback per litre of fuel and 2% cashback on in-store purchases
              (exclusions: tobacco, alcohol, lottery, gift cards, and other items as specified).
            </li>
          </ul>

          <h3 className="subHeadderText font-bold">Enjoy your earnings:</h3>
          <ul className={`bodyText list-disc text-left px-5 ${currentTheme === "light" ? "text-black" : "text-white"}`}>
            <li>Redeem your cashback anytime after registering and check your balance via the app or online.</li>
            <li>
              Cashback must be redeemed in increments of $5, $10, $15, $20, $25, $50, or $100.
              Transfers between virtual and physical cards are easy once registered.
            </li>
          </ul>
        </AnimateOnScroll>

        <div className=" flex justify-center lg:justify-start">
          <img src={pumpingGasImg} className="w-full max-w-sm lg:max-w-full rounded-lg" alt="GoMarket Store" />
        </div>
      </div>
      {/* Download Cashback App */}
      <div
        className={`py-12 overflow-hidden
          ${currentTheme === "light"
            ? "bg-gradient-to-r from-[#ffffff00] to-[#B9D6E9]"
            : "bg-gradient-to-r from-[#07284100] to-[#072841]"
          }`}
      >
        <div className='section grid md:grid-cols-3 grid-cols-1'>
          {/* Title */}
          <AnimateOnScroll className='col-span-2 text-center md:text-left flex flex-col my-auto'>
            <h2
              className={`headerText mb-5
                        ${currentTheme === "light"
                  ? "centexTextColour"
                  : "centexOrangeText"
                }`}
            >
              Download the Centex Cashback App!
            </h2>

            {/* Description */}
            <p className="my-8 bodyText ">
              Easily check your cashback balance, look up previous transactions, transfer balances,
              and find a participating Centex location near you!
              <br />
              <br />
              You'll also be able to check your sweepstakes entries and see each month's winning numbers.
              Download it now via the links below to get started.
            </p>

            {/* App Store Buttons */}
            <a href='https://qrplanet.com/k23bn7' target="_blank"><img src={AppStoreButtons} alt='App Store button' className="w-fit mb-6" /> </a>

          </AnimateOnScroll>
          {/* Phone App Image */}
          <div className="hidden md:flex h-[100px]">
            <img
              src={AppHomePage}
              className="h-[700px]"
              alt="Centex Cashback App preview"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CashBack;