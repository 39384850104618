import React, { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HeaderDropdown = ({ dropdownTitle, dropdownLink, links }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const currentTheme = useSelector(state => state.ui.theme);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="relative text-left">
            <div className='flex'>
                <div className='hover:scale-110 flex items-center text-center'>
                    <NavLink
                        to={dropdownLink}
                        className={({ isActive }) =>
                            isActive ? "transition-all underline underline-offset-8 text-[#F99D24] " : "cursor-pointer hover:scale-105 hover:text-[#F99D24] transition-all"
                        }
                    >
                        {dropdownTitle}
                    </NavLink>
                    <motion.button
                        className="inline-block ml-1 text-2xl"
                        initial={{ rotate: 0 }}
                        animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                        onClick={handleToggleDropdown}
                        onMouseEnter={handleMouseEnter} //onMouseLeave={handleMouseLeave}
                    >
                        <IoIosArrowDown />
                    </motion.button>
                </div>
                <AnimatePresence>
                    {isDropdownOpen && (
                        <motion.div
                            className={`absolute z-50 mt-5 w-fit rounded-b-xl p-3 drop-shadow-xl -left-4 text-base
                            ${currentTheme === "light" ? "bg-white text-gray-700" : "bg-[#072841] text-gray-100"}`}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 15 }}
                            exit={{ opacity: 0 }}
                            onMouseLeave={handleMouseLeave}
                        >
                            {links.map(({ name, link, clickAction }) => (
                                <NavLink
                                    to={link && link}
                                    className={({ isActive }) =>
                                        `${isActive && " text-[#F99D24]"} pt-3 block text-center hover:text-[#F99D24] font-light transition-all hover:scale-110`
                                    }
                                    // className="block px-2 py-2 text-center  hover:text-[#F99D24] font-thin transition-all hover:scale-105 hover:font-semibold"
                                    onClick={() => setIsDropdownOpen(false)}
                                    key={name}
                                >
                                    {name}
                                </NavLink>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default HeaderDropdown;
