import axios from "axios";

const BASE_URL = process.env.REACT_APP_WP_RESTAPI_ENDPOINT;

const getAllLocations = async () => {
  try {
    const response1 = await axios.get(BASE_URL + "/centex-locations/?per_page=100");
    const response2 = await axios.get(BASE_URL + "/centex-locations/?offset=100&per_page=100");

    // Combine the data from both responses into a single array
    const combinedData = [...response1.data, ...response2.data];

    return combinedData;
  } catch (error) {
    console.error('Error fetching Locations:', error);
    throw new Error('Failed to fetch locations');
  }
};

const getLocationbyID = async (id) => {
  try {
    const response = await axios.get(BASE_URL + "/centex-locations/" + id);
    return response?.data;
  } catch (error) {
    console.error('Error fetching the Location:', error);
    throw new Error('Failed to fetch the location');
  }
}

const locationService = {
  getAllLocations,
  getLocationbyID
}
export default locationService;